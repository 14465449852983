<template lang="pug">
label.radio-input(:class="{ checked: modelValue == value }")
  | {{ label }}

  input(type="radio", :name="name", :value="value", :checked="modelValue == value", @change="emitChange")

  span.checkmark

</template>
<script setup lang="ts">
defineProps({
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  modelValue: {
    type: String
  },
  value: {
    type: String
  }
})

const emit = defineEmits(["update:modelValue"])

const emitChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;

  emit("update:modelValue", value);
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'

.radio-input
  display: inline-block
  position: relative
  height: 36px
  padding-left: 24px
  cursor: pointer
  font-size: 14px
  font-weight: bold
  line-height: 36px
  margin: 0 8px

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0

    &:active
      outline: none
      box-shadow: none
      background-color: rgba(0, 0, 0, 0)

    &:checked ~ span
      border-color: $magenta

      &::after
        transform: translate(-50%, -50%) scale(1)

  .checkmark
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 0
    box-sizing: border-box
    height: 16px
    width: 16px
    border-radius: 50%
    border: 2px solid $labelColor
    transition: border-color .16s ease-in

    &::after
      content: ""
      display: block
      position: absolute
      top: 6px
      left: 6px
      width: 8px
      height: 8px
      border-radius: 50%
      background-color: $magenta
      transform: translate(-50%, -50%) scale(0)
      transition: transform .16s ease-in
</style>